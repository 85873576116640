import { useEffect, useState, useCallback } from "react";
import StoryblokClient from "storyblok-js-client";

const Storyblok = new StoryblokClient({
   accessToken: process.env.GATSBY_SB_GLOBAL,
   cache: {
      clear: "auto",
      type: "memory",
   },
});

export function useFeaturedPost(uuid) {
   const [featuredPost, setFeaturedPost] = useState();

   const reqParams = uuid
      ? {
           url: `cdn/stories/${uuid}`,
           params: {
              version: "published",
              find_by: "uuid",
           },
        }
      : {
           url: "cdn/stories/",
           params: {
              starts_with: `en/news/`,
              version: "published",
              sort_by: "published_at:desc",
           },
        };

   const getFeaturedPost = useCallback(
      async function getFeaturedPost() {
         await Storyblok.get(reqParams.url, reqParams.params)
            .then(({ data }) => {
               if (uuid) {
                  if (data.story) {
                     return setFeaturedPost(data.story);
                  }
                  return null;
               } else {
                  if (data.stories) {
                     return setFeaturedPost(data.stories[0]);
                  }
                  return null;
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      [uuid]
   );

   useEffect(() => {
      console.log(0, "FETCHING POST FROM SB");
      getFeaturedPost();
   }, [uuid, getFeaturedPost]);

   return featuredPost;
}
