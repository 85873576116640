import { useState, useEffect } from "react";

export function useMatchMedia(query) {
   if (typeof window !== "undefined") {
      const [match, setMatch] = useState(false);

      useEffect(() => {
         const updateMatch = () => setMatch(window.matchMedia(query).matches);

         updateMatch();
         window.matchMedia(query).addListener(updateMatch);
         return () => {
            window.matchMedia(query).removeListener(updateMatch);
         };
      }, [query]);

      return match;
   } else {
      return false;
   }
}
