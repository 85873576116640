import React, { createContext, useContext } from "react";

import { useMatchMedia } from "hooks";

const LocalStateContext = createContext(false);
const LocalStateProvider = LocalStateContext.Provider;

function MatchMediaProvider({ children }) {
   const isTablet = useMatchMedia("(min-width: 1024px)");
   const isDesktop = useMatchMedia("(min-width: 1280px)");

   return <LocalStateProvider value={{ isTablet, isDesktop }}>{children}</LocalStateProvider>;
}

function useMatchMediaContext() {
   const all = useContext(LocalStateContext);
   return all;
}

export { MatchMediaProvider, useMatchMediaContext };
