import { useState } from "react";
import findIndex from "lodash/findIndex";

export function useMemberModal(members) {
   const [open, setOpen] = useState(false);
   const [member, setMember] = useState({});

   const handleOpenModal = (event) => {
      const id = event.currentTarget.id;
      const clickedMember = { ...members.filter((item) => item._uid === id)[0] } || {};
      setMember(clickedMember);
      setOpen(true);
   };

   function handleCloseModal() {
      setOpen(false);
   }

   function nextMember(id) {
      const newMemberIndex = findIndex(members, { _uid: id });
      if (newMemberIndex >= 0 && newMemberIndex < members.length - 1) {
         const newMember = { ...members[newMemberIndex + 1] };
         return setMember(newMember);
      }
      const newMember = { ...members[0] };
      return setMember(newMember);
   }

   function prevMember(id) {
      const newMemberIndex = findIndex(members, { _uid: id });
      if (newMemberIndex > 0) {
         const newMember = { ...members[newMemberIndex - 1] };
         return setMember(newMember);
      }
      const newMember = { ...members[members.length - 1] };
      return setMember(newMember);
   }

   return [open, member, handleOpenModal, handleCloseModal];
}
