import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";

import * as smoothScroll from "smooth-scroll";
smoothScroll('a[href*="#"]');

import "fetch-polyfill";

import "assets/css/global.scss";

import React from "react";
import { MatchMediaProvider } from "context";

export async function onClientEntry() {
   if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      console.log("RUN BROWSER API");
      require("smooth-scroll")('a[href*="#"]');
   }

   if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
   }

   // Object-fit/Object-position polyfill for gatsby-image (IE)
   const testImg = document.createElement(`img`);
   if (testImg.style.objectFit === `undefined` || testImg.style.objectPosition === `undefined`) {
      import(`object-fit-images`)();
      console.log(`Object-fit/Object-position are polyfilled`);
   }
}

const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

const scrollToElement = (hash) => {
  const element = document.querySelector(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
  } else {
    scrollToTop();
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.search.includes("filters=")) return false;
  return true;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation) return;

  const isNewPath = location.pathname !== prevLocation.pathname;
  const hasHash = !!location.hash;

  setTimeout(() => {
    if (isNewPath) {
      hasHash ? scrollToElement(location.hash) : scrollToTop();
    }
    // If same route, do nothing (let browser handle default or smooth scroll)
  }, 0);
};

export const wrapRootElement = ({ element }) => {
   return <MatchMediaProvider>{element}</MatchMediaProvider>;
};
